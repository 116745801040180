body * {
    transition: all 0.3s ease !important;
}
.bg-class-for-test .bg-animated{
position: absolute;
background: #FFF;
border-radius: 20px;
height: 100%;
width: 100%;
top: 0;
left: 0;
transition: all 0.2s ease-in-out;
color: #222220 !important;
}
.bg-class-for-test:hover .bg-animated{
    background-color: #FFF;
    background: var(--Dark-Green---Sec, #114036);
box-shadow: 0px 10px 18px 0px rgba(161, 255, 117, 0.36);
    color: #FFF !important;
    top: 75px;
    height: calc(100% - 100px);
}