@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  transition: all 0.5s ease-in-out !important;
}
body * {
  transition: all 0.3s ease !important;
}
html{
  scroll-behavior: smooth;
  transition: all 0.5s ease-in-out !important;
}

@font-face {
  font-family: Manrope-Bold;
  src: url("./Assets/font-family/Manrope-Bold.ttf");
}
@font-face {
  font-family:Manrope-ExtraBold;
  src: url("./Assets/font-family/Manrope-ExtraBold.ttf");
}
@font-face {
  font-family:Manrope-Light;
  src: url("./Assets/font-family/Manrope-Light.ttf");
}
@font-face {
  font-family:Manrope-Medium;
  src: url("./Assets/font-family/Manrope-Medium.ttf");
}
@font-face {
  font-family:Manrope-Regular;
  src: url("./Assets/font-family/Manrope-Regular.ttf");
}
@font-face {
  font-family:Manrope-SemiBold;
  src: url("./Assets/font-family/Manrope-SemiBold.ttf");
}


.f-f-b-ex{
  font-family:Manrope-ExtraBold;
}

.f-f-b{
  font-family: Manrope-Bold;
}

.f-f-sm{
  font-family:Manrope-SemiBold;
}

.f-f-m{
  font-family:Manrope-Medium;
}

.f-f-r{
  font-family:Manrope-Regular;
}

.f-f-li{
  font-family:Manrope-Light;
}

.additional-bg{
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.50) 1.3%, rgba(194, 255, 165, 0.81) 36.58%, #D4FFC1 73.11%, #FFF 96.07%);
}

.contact-us{
  box-shadow: 0px 26px 30px 0px rgba(0, 0, 0, 0.06);
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('./Assets/elvotaimages/downarrow.png') no-repeat right 16px center;
  background-size: 14px; /* Adjust the size of the arrow image */

}

.Hero-bg{
  background-image: url('./Assets/elvotaimages/Hero-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 734px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .Hero-bg{
    height:536px;
  }
}


/* .powering-bg{
  border-radius: 20px;
opacity: 0.65;
background: linear-gradient(90deg, #114036 0%, rgba(17, 64, 54, 0.30) 100%) ;
backdrop-filter: blur(25.049999237060547px);

} */

.powering-bg {
  position: relative;
  border-radius: 20px;
  overflow: hidden; /* Ensures the background respects the border radius */
  width: 100%; /* Add width or a specific width if needed */
  height: 100%; /* Add height or a specific height if needed */
}


.powering-bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit; /* Inherit the border radius */
  background: linear-gradient(90deg, #114036 0%, rgba(17, 64, 54, 0.30) 100%) !important;
  opacity: 0.65;
  backdrop-filter: blur(25px);
  z-index: -1; /* Places the pseudo-element behind the content */
}

.powering-bg .content {
  position: relative;
  z-index: 1;
  color: white; /* Ensure content is visible against the background */
}



.slider-setting .slick-prev {
  right: 104px ;
  bottom: -17px ;
  width: 34px ;
  height: 34px ;
  z-index: 99999 ;
  left:unset ;
  top: unset;
}

.slider-setting .slick-next{
  right: 40px ;
  bottom: -17px ;
  width: 34px ;
  height: 34px ;
  top: unset;
}

.slider-setting .slick-prev:before, .slick-next:before{
  display: none !important;
}
.slider-setting .slick-dots{
  position: absolute ;
    bottom: -14px ;
    width: unset ;
}

.slider-setting .slick-dots li{
  margin:unset;
  width: 40px;
}


.slider-setting .slick-dots li button:before{
  font-size: 8px ;
  color: white ;
  opacity: unset ; 
  transition: all 0.5s ;
  content: '';
  width: 8px;
    height: 8px;
    border-radius: 20px;
    background: #E5E5DF;
}
.slider-setting .slick-dots li.slick-active button:before{
  font-size: 8px ;
  color: green ;
  opacity: unset ; 
  transition: all 0.5s ;
  content: '';
  width: 35px;
    height: 8px;
    border-radius: 20px;
    background: green;
}


@media screen and (max-width: 500px) {
  .slider-setting .slick-next{
    right: 4px ;
    bottom: -17px ;
    width: 20px ;
    height: 20px ;
  }
  .slider-setting .slick-prev {
    right: 34px ;
    bottom: -17px ;
    width: 20px ;
    height: 20px ;


  }
}


.footer-bg{
  background-image: url("./Assets/elvotaimages/Group\ 39.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}